<template>
  <NuxtLink :to="category.url" class="hover-scale">
    <div class="item" :style="{ backgroundImage: `url(${category.objectImgBg})` }">
      <div class="item__title">
        {{ category.text }}
      </div>
      <BaseImg :src="category.objectImg" :alt="category.text" />
      <div v-if="!!category.jackpot" class="item__sub-title">
        <div class="jackpot">{{ $formatNumberWithCommas(sumJackpot[category.jackpot], '.') }} Đ</div>
      </div>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import AnimateNumber from '~/components/common/animate-number-rolling.vue'

const { $pinia, $formatNumberWithCommas } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, sumJackpot, jackpot } = storeToRefs(store)

const props = defineProps({
  category: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: 0
  }
})
</script>
<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/featured-category/item.scss"></style>
