<template>
  <div style="background-color: #0a0a0a">
    <Banner />
    <FeatureCategory />
    <ProviderCategory />
    <TopGames />
    <DiscountCategory />
    <Footer />
  </div>
</template>
<script setup lang="ts">
import Banner from '~/components/mobile/pages/home/banner/index.vue'
import FeatureCategory from '~/components/mobile/pages/home/featured-category/index.vue'
import ProviderCategory from '~/components/mobile/pages/home/provider-category/index.vue'
import TopGames from '~/components/mobile/pages/home/top-games/index.vue'
import DiscountCategory from '~/components/mobile/pages/home/discount-category/index.vue'
import Footer from '~/components/mobile/pages/home/footer-homepage/index.vue'

onMounted(() => {
  setTimeout(() => {
    window.scrollTo({
      top: -100,
      behavior: 'smooth'
    })
  }, 200)
})
</script>
