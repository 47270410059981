<template>
  <section class="discount-category">
    <div class="container">
      <div class="discount-category__content">
        <div class="discount-bottom">
          <Swiper ref="provider" v-bind="swiperOption" class="mySwiper">
            <SwiperSlide
              v-for="(itemDiscountBottom, key) in listDiscountBottom"
              :key="key"
              class="discount-bottom__item"
            >
              <nuxt-link :to="itemDiscountBottom.link">
                <div class="banner" :class="itemDiscountBottom.class">
                  <BaseImg :src="itemDiscountBottom.img" :alt="itemDiscountBottom.alt" class="img-thumb" />
                </div>
              </nuxt-link>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow } from 'swiper'
import { PAGE_URLS } from '~~/config/page-url'

const swiperOption = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  centeredSlides: true,
  spaceBetween: 8,
  pagination: {
    clickable: true
  },
  modules: [Autoplay, Pagination, EffectCoverflow]
})

const listDiscountBottom = reactive([
  {
    alt: 'Ưu dãi thành viên mới',
    route: '',
    titleTop: 'ƯU ĐÃI',
    titleBottom: 'THÀNH VIÊN MỚI',
    class: 'newbie',
    img: '/assets/images/components/mobile/pages/home/discount-category/thumb-thanh-vien-moi.webp',
    link: PAGE_URLS.PROMOTION
  },
  {
    alt: 'Lên vip liền nhận tiền to',
    route: '',
    titleTop: 'LÊN VIP LIỀN',
    titleBottom: 'NHẬN TIỀN TO',
    class: 'vip',
    img: '/assets/images/components/mobile/pages/home/discount-category/thumb-nap-vip.webp',
    link: PAGE_URLS.PROMOTION + '/vip'
  },
  {
    alt: 'hoàn trả cao nhất',
    route: '',
    titleTop: 'HOÀN TRẢ',
    titleBottom: ' CAO NHẤT',
    class: 'refund',
    img: '/assets/images/components/mobile/pages/home/discount-category/thumb-hoan-tra.webp',
    link: PAGE_URLS.PROMOTION + '/the-loai/hoan-tra'
  }
])
</script>
<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/discount-category/index.scss"></style>
