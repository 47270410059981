<template>
  <div class="provider-items">
    <div v-for="(provider, index) in providers" :key="index" class="provider-item">
      <div class="provider-logo">
        <NuxtLink :to="provider.provider.url">
          <BaseImg :src="provider.provider.logo" :alt="provider.provider.name" lazy />
        </NuxtLink>
      </div>
      <div class="games">
        <div v-for="(game, idx) in provider.games" :key="idx"
             class="game"
            @click="onClickGame(game)"
        >
            <BaseImg :src="game.image" :alt="game.alt" lazy  />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseImg from '~~/components/common/base-img.vue'
import useNavigationGame from '~/composables/useNavigationGame'
import debounce from "lodash/debounce";
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
interface Provider {
  provider: {
    url: string
    logo: string
    name: string
  }
  games: Array<{
    url: string
    image: string
    alt: string
    maintain: boolean
  }>
}

const props = defineProps<{
  providers: Provider[]
}>()

const onClickGame = debounce((item) => {
  navigationCheckLoggedInAndOpenGame(item)
}, 50)
</script>

<style
  lang="scss"
  scoped
  src="~/assets/scss/components/desktop/pages/home/provider-category/provider-item.scss"
></style>
